<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t("message.new_product") }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton"
        @click="submit"
        >{{ $t("message.save") }}</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="resetForm('form')"
        >{{ $t("message.close") }}</el-button
      >
    </header>

    <div class="body__modal">
      <el-form
        ref="form"
        :model="form"
        size="small"
        class="aticler_m stylekhanForm"
      >
        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :sm="12">
              <div class="d-flex">
                <div class="text_a mr-3">
                  {{ $t("message.write_of_from") }} *
                </div>
                <el-form-item>
                  <el-date-picker
                    v-model="form.date"
                    type="datetime"
                    :placeholder="$t('message.delivery_time')"
                    :format="'dd.MM.yyyy HH:mm'"
                    :value-format="'dd-MM-yyyy HH:mm'"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="40">
            <el-col :sm="12">
              <el-form-item :label="$t('message.warehouse')">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <mainwarehouse
                        v-on:clear-x="afterClosed()"
                        v-model="form.mainwarehouse_id"
                        :mainwarehouse_id="form.mainwarehouse_id"
                        size="mini"
                      ></mainwarehouse>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :sm="12">
              <el-form-item :label="$t('message.user')">
                <el-row :gutter="5">
                  <el-col :span="22">
                    <el-form-item class="mb-0">
                      <users v-model="form.receiver_id"></users>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card class="box-card mb-4">
          <el-row :gutter="20">
            <el-col :span="18" class="mb-3">
              <el-input
                v-model="qrCode"
                ref="qrInput"
                @input="getProductByQr"
              ></el-input>
            </el-col>
            <el-col :span="24">
              <el-table border :data="items">
                <el-table-column label="#" width="30">
                  <template slot-scope="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.name')">
                  <template slot-scope="scope">{{
                    scope.row.product.name
                  }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.available')">
                  <template slot-scope="scope">{{
                    scope.row.available
                  }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.box_available')">
                  <template slot-scope="scope">{{
                    scope.row.box_available
                  }}</template>
                </el-table-column>
                <el-table-column :label="$t('message.quantity')">
                  <template slot-scope="scope">
                    <el-input
                      v-if="scope.row.available != 0"
                      v-model="scope.row.quantity"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.box_quantity')">
                  <template slot-scope="scope">
                    <el-input
                      v-if="scope.row.is_new"
                      v-model="scope.row.box_quantity"
                    ></el-input>
                    <span v-else>{{ scope.row.box_quantity }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('message.actions')">
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="removeWriteOffItem(scope.row)"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
            <!-- <el-col :span="24" class="mt-3">
                            <el-form-item
                                v-if="form.mainwarehouse_id"
                                :label="$t('message.products')"
                            >
                                <products
                                    @append="append"
                                    v-model="form.product_id"
                                    :product_id="form.product_id"
                                ></products>
                            </el-form-item>
                            <el-form-item
                                v-else
                                :label="$t('message.products')"
                            >
                                <el-select
                                    v-model="value"
                                    disabled
                                    :placeholder="$t('message.products')"
                                >
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> -->
          </el-row>
        </el-card>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import drawer from "@/utils/mixins/drawer";
import warehouses from "@/components/inventory-select/warehouses";
import mainwarehouse from "@/components/selects/mainwarehouse";
import products from "@/components/selects/products";
import users from "@/components/inventory-select/users";
import form from "@/utils/mixins/form";

export default {
  mixins: [drawerChild, drawer, form],
  components: {
    mainwarehouse,
    warehouses,
    users,
    products,
  },
  props: {
    writeOff: {
      default: null,
    },
  },
  data() {
    return {
      qrCode: null,
      options: [],
      value: "",
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      model: "writeOffs/model",
      rules: "writeOffs/rules",
      columns: "writeOffs/columns",
    }),
  },
  methods: {
    ...mapActions({
      serachByCode: "products/serachByCode",
      showProductRemainder: "movings/showrem",
      storeWriteOff: "writeOffs/store",
    }),
    beforeClose(done) {
      alert("asdasd");
      this.items = [];
      done();
    },
    getProductByQr: _.debounce(function (val) {
      if (val != "" && Number(val))
        this.serachByCode(val)
          .then((res) => {
            if (res.data.product != null) this.append(res.data.product);
            else
              this.$notify({
                title: this.$t("message.validation"),
                type: "error",
                position: "bottom right",
                message: "product not found",
              });
          })
          .finally((fin) => {
            this.qrCode = null;
          });
      else this.qrCode = null;
    }, 500),
    afterClosed() {
      this.items = [];
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.close();
    },
    append(product) {
      const item = this.items.find((item) => item.product_id == product.id);
      this.loadingItems = true;
      const payload = {
        product_id: product.id,
        warehouse_id: this.form.mainwarehouse_id,
      };
      this.showProductRemainder(payload)
        .then((response) => {
          const product = response.data.product;
          if (!item) {
            this.items.push({
              product_id: product.id,
              available: product.available ? product.available : 0,
              box_available: product.available ? product.available : 0,
              quantity: 1,
              box_quantity: 1,
              product: {
                name: product.name,
              },
            });
          } else {
            item.quantity += 1;
          }
          this.loadingItems = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    afterOpened() {},
    removeWriteOffItem(item) {
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    submit() {
      const payload = {
        warehouse_id: this.form.mainwarehouse_id,
        date: this.form.date.toString(),
        receiver_id: this.form.receiver_id,
        items: this.items,
      };

      this.storeWriteOff(payload)
        .then((response) => {
          this.$alert(response);
          this.close();
        })
        .catch((error) => {
          this.$alert(error);
        });
    },
  },
};
</script>
